@import '@/_styles/import';
.amount {
  word-spacing: -0.1em;
}

.price {
  .amount:has(+ .original-amount) {
    color: var(--discount-color, $vv-red);
  }

  .force-line-break {
    display: block;
  }
}
